// src/components/ProjectList.js

import React, { useState, useEffect } from 'react';
import { List, Input, Popconfirm, Button, Tooltip, Pagination, message } from 'antd';
import {
  DeleteOutlined,
  ProjectOutlined,
  TableOutlined,
  FileDoneOutlined,
  BookOutlined,
  SortAscendingOutlined
} from '@ant-design/icons';
import { deleteProject } from '../utils/project';

const ProjectList = ({ projects, selectedProjectId, onSelectProject, setProjects }) => {
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedProjects, setSortedProjects] = useState([]);
  const [sortOrder, setSortOrder] = useState('ascend');
  const pageSize = 5;

  // Update sorted projects when projects or sortOrder changes
  useEffect(() => {
    const sorted = [...projects].sort((a, b) => {
      if (sortOrder === 'ascend') {
        return a.name.localeCompare(b.name);
      }
      return b.name.localeCompare(a.name);
    });
    setSortedProjects(sorted);
  }, [projects, sortOrder]);

  const filteredProjects = sortedProjects.filter((project) =>
    project.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleDeleteProject = async (projectId) => {
    try {
      await deleteProject(projectId);
      setProjects((prevProjects) => prevProjects.filter((project) => project.id !== projectId));
      if (selectedProjectId === projectId) {
        onSelectProject(null);
      }
      message.success('Project deleted successfully');
    } catch (error) {
      message.error('Failed to delete project');
    }
  };

  // Function to get icon based on project type
  const getProjectIcon = (type) => {
    switch (type) {
      case 'table':
        return <TableOutlined />;
      case 'receipt':
        return <FileDoneOutlined />;
      case 'book_notes':
        return <BookOutlined />;
      default:
        return <ProjectOutlined />;
    }
  };

  // Pagination
  const startIndex = (currentPage - 1) * pageSize;
  const paginatedProjects = filteredProjects.slice(startIndex, startIndex + pageSize);

  const handleSortToggle = () => {
    setSortOrder(sortOrder === 'ascend' ? 'descend' : 'ascend');
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}>
      <Input.Search
        placeholder="Search Projects"
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
          setCurrentPage(1);
        }}
        style={{ marginBottom: 8, marginRight:10 }}
      />
      <Button onClick={handleSortToggle} style={{ marginBottom: 8 }}>
        <SortAscendingOutlined />({sortOrder === 'ascend' ? 'A-Z' : 'Z-A'})
      </Button>
      </div>
      <List
        dataSource={paginatedProjects}
        renderItem={(item) => (
          <List.Item
            onClick={() => onSelectProject(item.id)}
            style={{
              cursor: 'pointer',
              backgroundColor:
                item.id === selectedProjectId ? '#e6f7ff' : 'transparent',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>
              {getProjectIcon(item.type)} {item.name}
            </span>
            <Popconfirm
              title="Are you sure you want to delete this project?"
              onConfirm={() => handleDeleteProject(item.id)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete Project">
                <Button
                  type="text"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={(e) => e.stopPropagation()}
                />
              </Tooltip>
            </Popconfirm>
          </List.Item>
        )}
      />
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={filteredProjects.length}
        onChange={(page) => setCurrentPage(page)}
        style={{ marginTop: 16, textAlign: 'center' }}
      />
    </>
  );
};

export default ProjectList;
