// src/hooks/useSchoolSearch.js

import { useState } from 'react';
import { fetchSchoolNames } from '../utils/project';

const useSchoolSearch = () => {
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const handleSchoolSearch = async (value) => {
    if (selectedSchool) {
      return;
    }
    setSearchValue(value);
    if (value) {
      try {
        const results = await fetchSchoolNames(value);
        setSchoolOptions([]);
  
        const uniqueSchools = new Set(); // Track unique entries
        const options = results.filter((school) => {
          const uniqueKey = `${school.name} (${school.district_name})(${school.id})`;
          if (uniqueSchools.has(uniqueKey)) {
            return false; // Skip if already seen
          }
          uniqueSchools.add(uniqueKey); // Add to the set
          return true; // Include this school
        }).map((school) => {
          const uniqueKey = `${school.name} (${school.district_name})(${school.id})`; // Define uniqueKey here
          return {
            value: uniqueKey,
            label: `${school.name} (${school.district_name}) (${school.id})`,
          };
        });
  
        setSchoolOptions(options);
      } catch (error) {
        console.error('Error fetching school names:', error);
      }
    } else {
      setSchoolOptions([]);
    }
  };
  

  return {
    handleSchoolSearch,
    schoolOptions,
    setSchoolOptions,
    selectedSchool,
    setSelectedSchool,
    searchValue,
    setSearchValue,
  };
};

export default useSchoolSearch;
