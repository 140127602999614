// src/pages/ProjectDetail.js

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useProjectData from '../hooks/useProjectData';
import ProjectLayout from '../components/ProjectLayout';
import BreadcrumbComponent from '../components/BreadcrumbComponent';
import JobTable from '../components/JobTable';
import CreateJobModal from '../components/CreateJobModal';
import { Card, Button } from 'antd';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';

const ProjectDetail = () => {
  const { id: projectId } = useParams();
  console.log("projetc id from detail page", projectId);
  const [isCreateJobVisible, setIsCreateJobVisible] = useState(false);

  const {
    projectName,
    jobs,
    loading,
    handleJobCreation,
    handleActionClick,
    handleDeleteClick,
    handleRefreshClick
  } = useProjectData(projectId);

  const handleCreateJobOpen = () => {
    setIsCreateJobVisible(true);
  };

  const handleCreateJobClose = () => {
    setIsCreateJobVisible(false);
  };

  return (
    <ProjectLayout>
      <BreadcrumbComponent projectName={projectName} projectId={projectId} />

      {/* Project Title */}
      {/* <Title level={2}>
        <ProjectOutlined /> {projectName}
      </Title> */}

      {/* Card with JobTable and Create New Job Button */}
      <Card
        bordered={false}
        extra={
          <>
          <Button 
            icon={<ReloadOutlined />} 
            onClick={handleRefreshClick}
            style={{ marginRight: '20px' }}
          ></Button>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleCreateJobOpen}
          >
            New Job
          </Button>
          </>
        }
      >
        <JobTable
          jobs={jobs}
          loading={loading}
          projectId={projectId}
          handleActionClick={handleActionClick}
          handleDeleteClick={handleDeleteClick}
        />
      </Card>

      {/* Create Job Modal */}
      <CreateJobModal
        projectId={projectId}
        isVisible={isCreateJobVisible}
        onCancel={handleCreateJobClose}
        onFinish={handleJobCreation}
        loading={false}
      />
    </ProjectLayout>
  );
};

export default ProjectDetail;
