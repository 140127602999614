// src/components/CreateProjectModal.js
import React from 'react';
import { Modal, Form, Input, Button, Select } from 'antd';
import { ProjectOutlined, FileTextOutlined, ProfileOutlined, TableOutlined, FileDoneOutlined, BookOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const CreateProjectModal = ({ isVisible, onCancel, onFinish, form, newProject, setNewProject, loading }) => {
  return (
    <Modal
      title="Create New Project"
      visible={isVisible}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}  // Connect the form instance
      >
        <Form.Item
          label={<span><ProjectOutlined /> Project Name</span>}
          name="name"
          rules={[{ required: true, message: 'Please enter the project name' }]}
        >
          <Input
            value={newProject.name}
            onChange={(e) => setNewProject({ ...newProject, name: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          label={<span><FileTextOutlined /> Description</span>}
          name="description"
          rules={[{ required: true, message: 'Please enter the project description' }]}
        >
          <Input.TextArea
            value={newProject.description}
            onChange={(e) => setNewProject({ ...newProject, description: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          label={<span><ProfileOutlined /> Project Type</span>}
          name="type"
          rules={[{ required: true, message: 'Please select the project type' }]}
        >
          <Select
            value={newProject.type}
            onChange={(value) => setNewProject({ ...newProject, type: value })}
            placeholder="Select project type"
          >
            <Option value="table"><TableOutlined /> Table</Option>
            <Option value="receipt"><FileDoneOutlined /> Receipt</Option>
            <Option value="book_notes"><BookOutlined /> Book/Notes</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} icon={<PlusOutlined />}>
            Create Project
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateProjectModal;
