// src/components/ProjectDetails.js

import React, { useState, useEffect } from 'react';
import {
  Descriptions,
  Spin,
  message,
  Button,
  Modal,
  Form,
  Input,
  Select,
} from 'antd';
import {
  ProjectOutlined,
  FileTextOutlined,
  ProfileOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import { fetchProjectDetails } from '../utils/jobApi';
import useProjectData from '../hooks/useProjectData'; // Import the hook

const { TextArea } = Input;

const ProjectDetails = ({ projectId, detailed }) => {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(false);

  // State for modal visibility
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Form instance
  const [form] = Form.useForm();

  // Use the custom hook to get options data
  const { optionsData, handleDefaultsSubmit } = useProjectData(projectId);

  // Fetch project details
  useEffect(() => {
    const loadProjectDetails = async () => {
      setLoading(true);
      try {
        const projectData = await fetchProjectDetails(projectId);
        setProject(projectData);
      } catch (error) {
        message.error('Failed to load project details');
      } finally {
        setLoading(false);
      }
    };

    if (projectId) {
      loadProjectDetails();
    }
  }, [projectId]);

  if (loading) {
    return <Spin />;
  }

  if (!project) {
    return <p>No project selected.</p>;
  }

  const createdAt = project.created_at || project.createdAt;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    // Handle form submission here
    form
      .validateFields()
      .then(values => {
        handleDefaultsSubmit(values);
        // Do something with the values, e.g., send to API
        setIsModalVisible(false);
        form.resetFields();
        message.success('Defaults filled successfully');
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* Button to open modal */}
      <Button type="primary" onClick={showModal} style={{ marginBottom: 16 }}>
        Fill Defaults
      </Button>

      <Descriptions bordered column={1} size={detailed ? 'middle' : 'small'}>
        <Descriptions.Item label={<span><ProjectOutlined /> Name</span>}>
          {project.name}
        </Descriptions.Item>
        <Descriptions.Item label={<span><FileTextOutlined /> Description</span>}>
          {project.description}
        </Descriptions.Item>
        <Descriptions.Item label={<span><ProfileOutlined /> Type</span>}>
          {project.type}
        </Descriptions.Item>
        {createdAt && (
          <Descriptions.Item label={<span><ClockCircleOutlined /> Created At</span>}>
            {new Date(createdAt).toLocaleString()}
          </Descriptions.Item>
        )}
        {/* Add other project details as needed */}
      </Descriptions>

      {/* Modal with form */}
      <Modal
        title="Fill Defaults"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Submit"
      >
        <Form
          form={form}
          layout="vertical"
          name="defaultForm"
        >
          <Form.Item
            name="jobDescription"
            label="Job Description"
            rules={[{ required: true, message: 'Please enter job description' }]}
          >
            <TextArea rows={4} placeholder="Enter job description" />
          </Form.Item>

          <Form.Item
            name="jobColumns"
            label="Job Columns"
            rules={[{ required: true, message: 'Please enter job columns' }]}
          >
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Enter job columns"
              tokenSeparators={[',']}
            />
          </Form.Item>

          <Form.Item
            name="schoolName"
            label="School Name"
            rules={[{ required: true, message: 'Please select school name' }]}
          >
            <Select
              showSearch
              placeholder="Select a school"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {optionsData.schoolNames.map((name) => (
                <Select.Option key={name} value={name}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="scholarshipScheme"
            label="Scholarship Scheme"
            rules={[{ required: true, message: 'Please select scholarship scheme' }]}
          >
            <Select
              showSearch
              placeholder="Select a scholarship scheme"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {optionsData.scholarshipSchemes.map((scheme) => (
                <Select.Option key={scheme} value={scheme}>
                  {scheme}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ProjectDetails;
