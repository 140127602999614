// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { getUserRole } from '../utils/auth';  // Use the helper function

const ProtectedRoute = ({ children, allowedRoles }) => {
  const userRole = getUserRole();  // Get the current user's role

  // Check if the user role is allowed to access the route
  if (allowedRoles.includes(userRole)) {
    return children;
  }

  // If not, redirect to login page
  return <Navigate to="/login" />;
};

export default ProtectedRoute;
