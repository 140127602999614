// src/hooks/useAdminDashboardData.js

import { useState, useEffect } from 'react';
import { message } from 'antd';
import {
  fetchUserCountsByRole,
  fetchJobCountsByStatus,
  fetchVerifiedJobCounts,
  fetchUnassignedJobsCount,
  fetchAgentsPerformanceData,
  fetchSupervisorsAssignmentsData,
} from '../utils/dashboardApi';

const useAdminDashboardData = () => {
  const [userCounts, setUserCounts] = useState({
    agents: 0,
    supervisors: 0,
    admins: 0,
  });

  const [verifiedJobCounts, setVerifiedJobCounts] = useState({
    verified: 0,
    non_verified: 0,
  });

  const [jobStatusCounts, setJobStatusCounts] = useState({
    pending: 0,
    'in-progress': 0,
    completed: 0,
    failed: 0,
  });

  const [unassignedJobsCount, setUnassignedJobsCount] = useState(0);

  const [agentsPerformanceData, setAgentsPerformanceData] = useState([]);
  const [supervisorsAssignmentsData, setSupervisorsAssignmentsData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [loadingAgentsData, setLoadingAgentsData] = useState(false);
  const [loadingSupervisorsData, setLoadingSupervisorsData] = useState(false);

  const useDummyData = false; // Set this to false when API is ready

  useEffect(() => {
    const loadDashboardData = async () => {
      setLoading(true);
      try {
        const [
          userCountsData,
          verifiedJobCountsData,
          jobStatusCountsData,
          unassignedJobsData,
        ] = await Promise.all([
          fetchUserCountsByRole(),
          fetchVerifiedJobCounts(),
          fetchJobCountsByStatus(),
          fetchUnassignedJobsCount(),
        ]);

        setUserCounts(userCountsData);
        setVerifiedJobCounts(verifiedJobCountsData);
        setJobStatusCounts(jobStatusCountsData);
        setUnassignedJobsCount(unassignedJobsData.unassigned_jobs);

      } catch (error) {
        message.error('Failed to load dashboard data');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    loadDashboardData();
  }, []);

  useEffect(() => {
    const loadAgentsData = async () => {
      setLoadingAgentsData(true);
      try {
        if (useDummyData) {
          // Use dummy data
          const dummyAgentsData = [
            { id: 'agent1', name: 'Agent A', totalJobs: 50, verifiedJobs: 30 },
            { id: 'agent2', name: 'Agent B', totalJobs: 40, verifiedJobs: 35 },
            { id: 'agent3', name: 'Agent C', totalJobs: 60, verifiedJobs: 55 },
          ];
          setAgentsPerformanceData(dummyAgentsData);
        } else {
          // Fetch real data
          const agentsData = await fetchAgentsPerformanceData();
          setAgentsPerformanceData(agentsData);
        }
      } catch (error) {
        message.error('Failed to load agents data');
        console.error(error);
      } finally {
        setLoadingAgentsData(false);
      }
    };

    loadAgentsData();
  }, [useDummyData]);

  useEffect(() => {
    const loadSupervisorsData = async () => {
      setLoadingSupervisorsData(true);
      try {
        if (useDummyData) {
          // Use dummy data
          const dummySupervisorsData = [
            { id: 'supervisor1', name: 'Supervisor X', assignedJobs: 80 },
            { id: 'admin1', name: 'Admin Y', assignedJobs: 120 },
            { id: 'supervisor2', name: 'Supervisor Z', assignedJobs: 60 },
          ];
          setSupervisorsAssignmentsData(dummySupervisorsData);
        } else {
          // Fetch real data
          const supervisorsData = await fetchSupervisorsAssignmentsData();
          setSupervisorsAssignmentsData(supervisorsData);
        }
      } catch (error) {
        message.error('Failed to load supervisors data');
        console.error(error);
      } finally {
        setLoadingSupervisorsData(false);
      }
    };

    loadSupervisorsData();
  }, [useDummyData]);

  return {
    userCounts,
    verifiedJobCounts,
    jobStatusCounts,
    unassignedJobsCount,
    agentsPerformanceData,
    supervisorsAssignmentsData,
    loading,
    loadingAgentsData,
    loadingSupervisorsData,
  };
};

export default useAdminDashboardData;
