// src/components/VerificationProgress.js
import React from 'react';
import { Card, Typography, Progress } from 'antd';

const VerificationProgress = ({ verificationData }) => {
  return (
    <Card style={{ margin: '16px 0' }}>
      <Typography.Title level={5}>
        Verification Progress: {verificationData.verified} /{' '}
        {verificationData.total} Images Verified
      </Typography.Title>
      <Progress
        percent={Math.round(verificationData.percent)}
        status={verificationData.unverified === 0 ? 'success' : 'active'}
        format={(percent) => `${percent}% Completed`}
      />
    </Card>
  );
};

export default VerificationProgress;
