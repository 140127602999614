// src/pages/JobDetail.js

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Card, Spin, message, Row, Col, Form, Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';

import BreadcrumbComponent from '../components/BreadcrumbComponent';
import ProjectLayout from '../components/ProjectLayout';
import useSaveJobOutput from '../hooks/useSaveJobOutput';
import { getAccessToken } from '../utils/auth';
import useProjectData from '../hooks/useProjectData';
import VerificationProgress from '../components/VerificationProgress';
import ImageNavigator from '../components/ImageNavigator';
import OutputForm from '../components/OutputForm';
import useSchoolSearch from '../hooks/useSchoolSearch';
import useFormManager from '../hooks/useFormManager';
import useJobDetails from '../hooks/useJobDetails';
import useImageNavigation from '../hooks/useImageNavigation';
import { verifyImage, registerStudents, saveOutputData ,syncAgData} from '../utils/jobApi';
import { set } from 'lodash';

const JobDetail = () => {
  const { id: projectId, jobId } = useParams();
  const [form] = Form.useForm();
  const [verifying, setVerifying] = useState(false);
  const [saving, setSaving] = useState(false);

  const { optionsData, getVerificationProgress } = useProjectData(projectId);
  const { tableData, setFormFields, handleAddRow, handleRemoveRow } = useFormManager(form);
  const { jobDetails, setJobDetails, verificationData, loading } = useJobDetails(
    jobId,
    setFormFields,
    getVerificationProgress
  );
  const { saveOutput } = useSaveJobOutput();
  const {
    handleSchoolSearch,
    schoolOptions,
    setSchoolOptions,
    selectedSchool,
    setSelectedSchool,
    searchValue,
    setSearchValue,
  } = useSchoolSearch();
  const {
    currentImageIndex,
    handlePrevImage,
    handleNextImage,
    saveCurrentOutput,
  } = useImageNavigation(jobDetails, form, tableData, setFormFields, setJobDetails);
  const [firstDropdownValue, setFirstDropdownValue] = useState(null);
  const [secondDropdownOptions, setSecondDropdownOptions] = useState([]);
  const [loadingSync, setLoadingSync] = useState(false); // State for sync button loading

  const handleSyncClick = async () => {
    setLoadingSync(true);
    message.loading('Syncing AG data...');
  
    try {
      await syncAgData();
      message.success('AG data synced successfully!'); 
    } catch (error) {
      console.error('Error syncing AG data:', error);
    } finally {
      setLoadingSync(false);
    }
  };
  

  const handleSave = async () => {
    try {
      saveCurrentOutput();
      await saveOutput(jobId, jobDetails.job_output);
    } catch (error) {
      // Error message is already handled in the hook
    }
  };

  const handleVerify = async () => {
    try {
      await form.validateFields();
      setVerifying(true);
      const token = getAccessToken();
      if (!token) {
        throw new Error('User is not authenticated. Please log in.');
      }
      if (!jobDetails || !jobDetails.name) {
        console.log('Job name is not available for verification.');
        setVerifying(false);
        return;
      }

      const jobName = jobDetails.name;
      const currentOutputValues = form.getFieldsValue();

      const schoolData = {
        schoolName: currentOutputValues.schoolName,
        scholarshipScheme: currentOutputValues.scholarshipScheme,
        package_id: currentOutputValues.packageSchemes,
      };

      const outputData = {};
      tableData.forEach((row) => {
        const rowKey = row.key;
        if (currentOutputValues[rowKey]) {
          outputData[rowKey] = currentOutputValues[rowKey];
        }
      });

      const requestBody = {
        job: jobName,
        image: jobDetails.image_paths[currentImageIndex],
        index: currentImageIndex,
        output: outputData,
      };
      const verifyBody = {
        jobName,
        Image_path: jobDetails.image_paths[currentImageIndex],
        schoolData,
        outputData,
      };
      try {
        const response = await verifyImage(token, requestBody);
        message.success('Verification request sent successfully',0);
        console.log('Verification request sent successfully:', response);
      } catch (error) {
        message.error('Failed to send verification request',0);
        console.log(
          'Failed to send verification request:',
          error.response ? error.response.data : error.message
        );
      }

      try {
        console.log(schoolData);
        message.loading('Registering students...', 0);
        const registerStudentResponse = await registerStudents(token, verifyBody);
        console.log('Register Student:', registerStudentResponse);
        const { success_count, failed_count } = registerStudentResponse;
        const messageText = `Register Student: ${success_count} successful, ${failed_count} failed.`;
        message.destroy();
        message.success(messageText,3);
      } catch (error) {
        message.destroy();

        message.error('Failed to register student',0);
        console.log(
          'Failed to register student:',
          error.response ? error.response.data : error.message
        );
      } finally {
        setVerifying(false);
      }
    } catch (errorInfo) {
      console.log('Validation Failed:', errorInfo);
      message.error('Please fix the validation errors before proceeding.');
      setVerifying(false);
    }
  };
  const handleSaving = async () => {
    try {
      await form.validateFields();
      setSaving(true);
      const token = getAccessToken();
      if (!token) {
        throw new Error('User is not authenticated. Please log in.');
      }
      const jobName = jobDetails.name;
      const currentOutputValues = form.getFieldsValue();
      const outputData = {};
      tableData.forEach((row) => {
        const rowKey = row.key;
        if (currentOutputValues[rowKey]) {
          outputData[rowKey] = currentOutputValues[rowKey];
        }
      });
      const saveOutputBody = {
        job: jobName,
        image: jobDetails.image_paths[currentImageIndex],
        index: currentImageIndex,
        output: outputData,
      };
      const loadingMessage = message.loading('Saving...', 0);
      try {
        const response = await saveOutputData(token, saveOutputBody);
        loadingMessage();
        message.success('Output saved successfully');
        console.log('Output saved successfully:', response);
      } catch (error) {
        loadingMessage();
        console.log(
          'Failed to save output:',
          error.response ? error.response.data : error.message
        );
      } finally {
        loadingMessage();
        setSaving(false);
      }
    } catch (errorInfo) {
      console.log('Saving Failed:', errorInfo);
      message.error('Saving Failed.');
      setSaving(false);
    }
  };
  if (loading) {
    return (
      <ProjectLayout>
        <Spin />
      </ProjectLayout>
    );
  }

  if (!jobDetails) {
    return (
      <ProjectLayout>
        <Typography.Text type="danger">Failed to load job details.</Typography.Text>
      </ProjectLayout>
    );
  }

  const currentImagePath = jobDetails.image_paths
    ? jobDetails.image_paths[currentImageIndex]
    : '';

  return (
    <ProjectLayout>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <BreadcrumbComponent
          projectId={projectId}
          projectName={jobDetails.project_name}
        />
        <Button
          type="primary"
          icon={<SyncOutlined />}
          htmlType="button"
          loading={loadingSync}
          onClick={handleSyncClick}
        >
          {loadingSync ? <Spin size="small" /> : 'Sync'}
        </Button>
      </div>

      <VerificationProgress verificationData={verificationData} />

      <Card style={{ marginBottom: '24px' }}>
        <Row gutter={[16, 16]}>


          {/* Image Preview */}

          <ImageNavigator
            currentImagePath={currentImagePath}
            jobDetails={jobDetails}
            currentImageIndex={currentImageIndex}
            handlePrevImage={handlePrevImage}
            handleNextImage={handleNextImage}
          />

          {/* Output Form */}
          <Col xs={24} md={16}>
            <OutputForm
              form={form}
              handleSave={handleSave}
              handleSchoolSearch={handleSchoolSearch}
              selectedSchool={selectedSchool}
              setSelectedSchool={setSelectedSchool}
              setSchoolOptions={setSchoolOptions}
              setSearchValue={setSearchValue}
              schoolOptions={schoolOptions}
              searchValue={searchValue}
              setFirstDropdownValue={setFirstDropdownValue} // Ensure this is defined
              setSecondDropdownOptions={setSecondDropdownOptions} // Correctly defined
              secondDropdownOptions={secondDropdownOptions} // Pass it down
              optionsData={optionsData}
              tableData={tableData}
              jobData={jobDetails}
              jobColumns={jobDetails.columns}
              verifying={verifying}
              saving={saving}
              handleVerify={handleVerify}
              handleSaving={handleSaving}
              loadingSync={loadingSync}
              handleSyncClick={handleSyncClick}
              handleAddRow={handleAddRow}
              handleRemoveRow={handleRemoveRow}
            />
          </Col>
        </Row>
      </Card>
    </ProjectLayout>
  );
};

export default JobDetail;
