// src/hooks/useCopyToClipboard.js

import { useState } from 'react';

/**
 * Custom hook to handle copying text to the clipboard.
 * @returns {Array} - [copied, copy] where `copied` is a boolean and `copy` is a function.
 */
const useCopyToClipboard = () => {
  const [copied, setCopied] = useState(false);

  /**
   * Copies the provided text to the clipboard.
   * @param {string} text - The text to copy.
   */
  const copy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    } catch (error) {
      setCopied(false);
      // Optionally, display an error message
    }
  };

  return [copied, copy];
};

export default useCopyToClipboard;
