// src/utils/agentDashboardApi.js

import axios from 'axios';
import { getAccessToken } from './auth';

import { API_URL } from '../utils/jobApi';


// Fetch assigned jobs for an agent
export const fetchAgentAssignedJobs = async () => {
  const response = await axios.get(`${API_URL}/users/unverified/jobs`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
  return response.data;
};

// Fetch verified jobs for an agent
export const fetchAgentVerifiedJobs = async () => {
  const response = await axios.get(`${API_URL}/users/verified/jobs`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
  return response.data;
};
