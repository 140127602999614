// src/pages/ProjectJobManagement.js

import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Button, Card, message } from 'antd';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';

import CreateProjectModal from '../components/CreateProjectModal';
import CreateJobModal from '../components/CreateJobModal';
import ProjectList from '../components/ProjectList';
import JobList from '../components/JobList';
import JobDetails from '../components/JobDetails';
import ProjectDetails from '../components/ProjectDetails';

import { fetchProjects, createProject } from '../utils/project';
import useProjectData from '../hooks/useProjectData';

const { Content } = Layout;

const ProjectJobManagement = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [isProjectModalVisible, setIsProjectModalVisible] = useState(false);
  const [isJobModalVisible, setIsJobModalVisible] = useState(false);
  const [newProject, setNewProject] = useState({ name: '', description: '', type: '' });
  const [loading, setLoading] = useState(false);

  // Load projects
  useEffect(() => {
    const loadProjects = async () => {
      try {
        const response = await fetchProjects();
        if (response) {
          setProjects(response.map((project) => ({
            ...project,
            key: project.id,
          })));
        }
      } catch (error) {
        message.error('Failed to load projects');
        console.error(error);
      }
    };

    loadProjects();
  }, []);

  // Use custom hook to fetch jobs for selected project
  const {
    projectName,
    jobs,
    loading: jobsLoading,
    handleJobCreation,
    handleActionClick,
    handleDeleteClick,
    handleRefreshClick,
  } = useProjectData(selectedProjectId);

  // Handlers for selecting project and job
  const handleProjectSelect = (projectId) => {
    setSelectedProjectId(projectId);
    setSelectedJobId(null); // Reset selected job
  };

  const handleJobSelect = (jobId) => {
    setSelectedJobId(jobId);
  };

  // Handle create project
  const handleCreateProject = async () => {
    if (!newProject.name || !newProject.description || !newProject.type) {
      message.error('Please fill in all fields.');
      return;
    }

    setLoading(true);
    try {
      // Call the backend to create a new project
      const response = await createProject(newProject);

      if (response._id && response.name) {
        // Update the local project list with the returned project data, including the MongoDB ID
        setProjects([...projects, { ...newProject, id: response._id, key: response._id }]);
        message.success('Project created successfully!');

        // Clear the form fields after submission
        setNewProject({ name: '', description: '', type: '' });
      } else {
        message.error('Failed to create project');
      }
    } catch (error) {
      message.error('An error occurred while creating the project');
      console.log(error);
    } finally {
      setLoading(false);
      setIsProjectModalVisible(false);
    }
  };

  // Handle create job
  const handleCreateJobOpen = () => {
    setIsJobModalVisible(true);
  };

  const handleCreateJobClose = () => {
    setIsJobModalVisible(false);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content
        style={{
          padding: '24px',
          background: '#f0f2f5',
          overflow: 'auto',
        }}
      >
        {/* Two-Column Layout */}
        <Row gutter={[16, 16]}>
          {/* Project List */}
          <Col xs={24} sm={24} md={8} style={{ display: 'flex', flexDirection: 'column' }}>
            <Card
              title="Projects"
              extra={
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setIsProjectModalVisible(true)}
                >
                  New Project
                </Button>
              }
              style={{ flex: 1 }}
            >
              <ProjectList
                projects={projects}
                selectedProjectId={selectedProjectId}
                onSelectProject={handleProjectSelect}
                setProjects={setProjects}
              />
            </Card>
          </Col>

          {/* Job List */}
          <Col xs={24} sm={24} md={16} style={{ display: 'flex', flexDirection: 'column' }}>
            <Card
              title={`Jobs ${projectName ? `for ${projectName}` : ''}`}
              extra={
                selectedProjectId && (
                  <>
                    <Button
                      icon={<ReloadOutlined />}
                      onClick={handleRefreshClick}
                      style={{ marginRight: '8px' }}
                    >
                      Refresh
                    </Button>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={handleCreateJobOpen}
                    >
                      New Job
                    </Button>
                  </>
                )
              }
              style={{ flex: 1 }}
            >
              <JobList
                jobs={jobs}
                loading={jobsLoading}
                onSelectJob={handleJobSelect}
                selectedJobId={selectedJobId}
                handleActionClick={handleActionClick}
                handleDeleteClick={handleDeleteClick}
                projectId={selectedProjectId}
              />
            </Card>
          </Col>
        </Row>

        {/* Details Section Below */}
        <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
          <Col xs={24}>
            <Card title="Details">
              {selectedJobId ? (
                <JobDetails jobId={selectedJobId} detailed />
              ) : selectedProjectId ? (
                <ProjectDetails projectId={selectedProjectId} detailed />
              ) : (
                <p>Please select a project or job to view details.</p>
              )}
            </Card>
          </Col>
        </Row>

        {/* Create Project Modal */}
        <CreateProjectModal
          isVisible={isProjectModalVisible}
          onCancel={() => setIsProjectModalVisible(false)}
          onFinish={handleCreateProject}
          form={null}
          newProject={newProject}
          setNewProject={setNewProject}
          loading={loading}
        />

        {/* Create Job Modal */}
        <CreateJobModal
          projectId={selectedProjectId}
          isVisible={isJobModalVisible}
          onCancel={handleCreateJobClose}
          onFinish={handleJobCreation}
          loading={false}
        />
      </Content>
    </Layout>
  );
};

export default ProjectJobManagement;
