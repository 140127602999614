// src/hooks/useInviteAgent.js

import { useState } from 'react';
import { inviteAgent } from '../utils/inviteApi';

/**
 * Custom hook to handle inviting a new agent.
 * @returns {Object} - Contains state variables and the invite function.
 */
const useInviteAgent = () => {
  const [loading, setLoading] = useState(false);
  const [registrationToken, setRegistrationToken] = useState('');
  const [success, setSuccess] = useState(false);
  const [messageText, setMessageText] = useState('');

  /**
   * Handles the invitation process.
   * @param {string} username - The username to invite.
   */
  const handleInvite = async (username, role) => {
    setLoading(true);
    setRegistrationToken('');
    setSuccess(false);
    setMessageText('');

    try {
      const response = await inviteAgent(username, role);
      if (response.success) {
        setRegistrationToken(response.registration_token);
        setSuccess(true);
        setMessageText(response.msg || 'Token Generated!');
      } else {
        setSuccess(false);
        setMessageText(response.msg || 'Failed to generate token.');
      }
    } catch (error) {
      setSuccess(false);
      setMessageText(error.message || 'An error occurred.');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    registrationToken,
    success,
    messageText,
    handleInvite,
  };
};

export default useInviteAgent;
