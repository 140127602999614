// src/components/Navbar.js
import React from 'react';
import { Menu, Modal } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { DashboardOutlined, LoginOutlined, ProjectOutlined, AuditOutlined, PoweroffOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { logoutUser, getUserRole } from '../utils/auth';  // Import logout and role helper

const Navbar = ({ isAuthenticated, setIsAuthenticated, setRole }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = React.useState(location.pathname);
  const role = getUserRole();  // Get the current role to conditionally show items

  const { confirm } = Modal;

  const LogoutMenuItem = () => {
    const handleLogout = () => {
      confirm({
        title: 'Confirm Logout',
        icon: <PoweroffOutlined />, // Optional: Add an icon to the modal
        content: 'Are you sure want to logout?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          logoutUser();  // Clear the session
          setIsAuthenticated(false);  // Update the authentication state
          setRole(null);  // Clear the role
          navigate('/login');  // Redirect to login
        },
        onCancel() {
          console.log('Logout canceled.');
        },
      });
    };

    return (
      <Menu.Item
  key="logout"  // Changed from '/logout' to 'logout'
  icon={<PoweroffOutlined style={{ fontSize: '27px', fontWeight: '900' }} />}
  style={{
    marginLeft: 'auto',
    color: 'red',
    paddingTop: '15px',
    paddingRight: '5px',
  }}
  onClick={handleLogout}
>
</Menu.Item>


    );
  };

  const handleMenuClick = (e) => {
    if (e.key !== 'logout') {  // Exclude 'logout' from being handled here
      setCurrent(e.key);
      navigate(e.key);  // Navigate to the selected page
    }
    // No action needed for 'logout' as it's handled by LogoutMenuItem
  };
  
  

  return (
    <Menu
      mode="horizontal"
      selectedKeys={[current]}
      onClick={handleMenuClick}
      style={{ display: 'flex', justifyContent: 'space-between', fontWeight:"600" }}
    >
      {/* Logo Image */}
      <Menu.Item key="logo" disabled style={{ cursor: 'default' }}>
        <img src="/favicon-black.png" alt="Next AI" style={{ height: '35px', marginRight: '16px', marginTop:'7px', marginBottom:'0px' }} />
        <span style={{color:"black", marginTop:"5px", fontSize: "22px", fontStyle:"bold", fontWeight:"400", letterSpacing:"2px", marginLeft:"0px"}}>Aroma</span>
      </Menu.Item>

      {/* Left-aligned menu items */}
      <Menu.Item style={{paddingTop:"7px"}} key="/dashboard" icon={<DashboardOutlined />}>
        Dashboard
      </Menu.Item>
      {/* Show Project Management menu only for admin and supervisor */}
      {(role === 'admin' || role === 'supervisor') && (
        <>
          <Menu.Item style={{paddingTop:"7px"}} key="/project-management" icon={<ProjectOutlined />}>
            Projects
          </Menu.Item>
          <Menu.Item style={{paddingTop:"7px"}} key="/assign-jobs" icon={<AuditOutlined />}>
            Assign Jobs
          </Menu.Item>
        </>
      )}

      <Menu.Item key="/failed-jobs" style={{paddingTop:"7px"}} icon={<ExclamationCircleOutlined />}>
       Activity logs
      </Menu.Item>

      {/* Right-aligned Login/Logout button */}
      {isAuthenticated ? (
        <LogoutMenuItem />
      ) : (
        <Menu.Item key="/login" icon={<LoginOutlined />} style={{ marginLeft: 'auto' }}>
          Login
        </Menu.Item>
      )}
    </Menu>
  );
};

export default Navbar;
