// src/utils/assignJobsApi.js

import axios from 'axios';
import { getAccessToken } from './auth';  // Ensure this function is correctly implemented
import { API_URL } from '../utils/jobApi';

// Fetch all projects and their associated jobs
export const fetchProjectsData = async () => {
  try {
    const token = getAccessToken();

    if (!token) {
      throw new Error('User is not authenticated. Please log in.');
    }

    // Fetch projects
    const projectsResponse = await axios.get(`${API_URL}/projects`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const projects = projectsResponse.data;

    // For each project, fetch its jobs
    const projectsData = await Promise.all(
      projects.map(async (project) => {
        const jobsResponse = await axios.get(`${API_URL}/jobs/${project.id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        const jobs = jobsResponse.data.map((job) => ({
            id: String(job._id), // Ensure ID is a string
            name: job.name,
            description: job.description,
            verified: job.verified,
            assigned_agent_id: job.assigned_agent_id ? String(job.assigned_agent_id) : null, // Convert to string
            projectId: String(project.id), // Ensure projectId is a string
            projectName: project.name,
          }));

          

        return {
          id: project.id,
          name: project.name,
          jobs,
        };
      })
    );

    return projectsData;
  } catch (error) {
    console.error('Error fetching projects data:', error);
    throw error;
  }
};

// Fetch all agents
export const fetchAgentsData = async () => {
  try {
    const token = getAccessToken();

    if (!token) {
      throw new Error('User is not authenticated. Please log in.');
    }

    const response = await axios.get(`${API_URL}/agents/all`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const agents = response.data.agents.map((agent) => ({
        id: String(agent._id), // Ensure ID is a string
        username: agent.username,
        name: agent.username,
      }));

    return agents;
  } catch (error) {
    console.error('Error fetching agents data:', error);
    throw error;
  }
};

// API to assign agent to job
export const assignAgentToJob = async (jobId, agentUsername) => {
    try {
      const token = getAccessToken();
  
      if (!token) {
        throw new Error('User is not authenticated. Please log in.');
      }
  
      const response = await axios.post(
        `${API_URL}/jobs/${jobId}/assign`,
        null,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          params: {
            agent_username: agentUsername,
          },
        }
      );
  
      return response.data;
    } catch (error) {
      console.error(`Error assigning agent to job ${jobId}:`, error);
      throw error;
    }
  };

  export const unassignAgentFromJobs = async (agentId) => {
    try {
      const token = getAccessToken();
  
      if (!token) {
        throw new Error('User is not authenticated. Please log in.');
      }
  
      console.log(`API Call - Unassigning agentId: ${agentId}`);
  
      const response = await axios.post(
        `${API_URL}/agents/${agentId}/unassign`,
        null,  // No body needed
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
  
      return response.data;
    } catch (error) {
      console.error(`Error unassigning agent ${agentId}:`, error.response?.data || error.message);
      throw error;
    }
  };
  
