// src/components/JobList.js

import React, { useState } from 'react';
import { Table, Input, Button, Tooltip, Popconfirm } from 'antd';
import {
  ThunderboltOutlined,
  DeleteOutlined,
  ProjectOutlined,
  RightCircleOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const JobList = ({
  jobs,
  loading,
  onSelectJob,
  selectedJobId,
  handleActionClick,
  handleDeleteClick,
  projectId,
}) => {
  const [searchText, setSearchText] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const navigate = useNavigate();

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const filteredJobs = jobs.filter((job) =>
    job.name.toLowerCase().includes(searchText.toLowerCase())
  );

  // Render status with icons and tooltips
  const renderStatus = (status) => {
    let statusInfo = {
      text: status,
      color: 'default',
      icon: null,
      tooltip: '',
    };

    switch (status) {
      case 'pending':
        statusInfo = {
          text: 'Pending',
          color: 'warning',
          icon: <ExclamationCircleOutlined />,
          tooltip: 'Job is pending and awaiting execution.',
        };
        break;
      case 'in-progress':
        statusInfo = {
          text: 'In Progress',
          color: 'processing',
          icon: <SyncOutlined spin style={{ color: 'blue' }} />,
          tooltip: 'Job is currently being processed.',
        };
        break;
      case 'completed':
        statusInfo = {
          text: 'Completed',
          color: 'success',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
          tooltip: 'Job has been completed successfully.',
        };
        break;
      case 'failed':
        statusInfo = {
          text: 'Failed',
          color: 'error',
          icon: <CloseCircleOutlined style={{ color: 'red' }} />,
          tooltip: 'Job execution failed. Please check the logs.',
        };
        break;
      default:
        statusInfo = {
          text: status,
          color: 'default',
          icon: null,
          tooltip: '',
        };
        break;
    }

    return (
      <Tooltip title={statusInfo.tooltip}>
        {statusInfo.icon} {statusInfo.text}
      </Tooltip>
    );
  };

  const isExecutable = (status) => {
    return status === 'pending' || status === 'failed';
  };

  const columns = [
    {
      title: (
        <span>
          <ProjectOutlined /> Job Name
        </span>
      ),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => renderStatus(status),
      filters: [
        { text: 'Pending', value: 'pending' },
        { text: 'In Progress', value: 'in-progress' },
        { text: 'Completed', value: 'completed' },
        { text: 'Failed', value: 'failed' },
      ],
      filteredValue: sortedInfo.status || null,
      onFilter: (value, record) => record.status.includes(value),
    },
    {
      title: 'Actions',
      dataIndex: '_id',
      key: 'actions',
      render: (jobId, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {record.status === 'completed' ? (
            <>
              <Tooltip title="Go to Job Details">
                <Button
                  type="primary"
                  icon={<RightCircleOutlined />}
                  onClick={() => navigate(`/project/${projectId}/job/${jobId}`)}
                  style={{ marginRight: '8px' }}
                >
                  Go To
                </Button>
              </Tooltip>
              <Tooltip title="Re-execute this job">
                <Button
                  type="primary"
                  icon={<ThunderboltOutlined />}
                  onClick={() => handleActionClick(jobId)}
                >
                  Re-Execute
                </Button>
              </Tooltip>
            </>
          ) : (
            <Tooltip
              title={
                isExecutable(record.status)
                  ? 'Execute this job'
                  : 'Job cannot be executed unless it is pending or failed'
              }
            >
              <Button
                type="primary"
                icon={<ThunderboltOutlined />}
                onClick={() => handleActionClick(jobId)}
                disabled={!isExecutable(record.status)}
                style={{ marginRight: '8px' }}
              >
                Execute
              </Button>
            </Tooltip>
          )}
          <Popconfirm
            title="Are you sure you want to delete this job?"
            onConfirm={() => handleDeleteClick(jobId)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete Job">
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                onClick={(e) => e.stopPropagation()}
              />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    }
    ,
  ];

  return (
    <>
      <Input.Search
        placeholder="Search Jobs"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: 8 }}
      />
      <Table
        columns={columns}
        dataSource={filteredJobs}
        loading={loading}
        rowKey={(record) => record._id}
        pagination={{
          pageSize: 10,
          showQuickJumper: true,
        }}
        onChange={handleChange}
        onRow={(record) => ({
          onClick: () => onSelectJob(record._id),
          style: {
            cursor: 'pointer',
            backgroundColor:
              record._id === selectedJobId ? '#e6f7ff' : 'transparent',
          },
        })}
      />
    </>
  );
};

export default JobList;
