// src/hooks/useRegisterAgent.js

import { useState } from 'react';
import { message } from 'antd';
import { registerAgent } from '../utils/api'; // Function to handle agent registration

const useRegisterAgent = () => {
  const [loading, setLoading] = useState(false);

  const handleRegister = async (registrationData) => {
    setLoading(true);
    try {
      const response = await registerAgent(registrationData);
      const { success, msg } = response;
      
      // Show the message based on the API response
      if (success) {
        message.success({'content': msg || 'Agent registered successfully!', duration: 5});
        return true;
      } else {
        message.error({'content': msg || 'Registration failed!', duration: 5});
        return false;
      }
    } catch (error) {
      message.error({'content': 'Error occurred during registration!', duration: 5});
      return false;
    } finally {
      setLoading(false);
    }
  };

  return { handleRegister, loading };
};

export default useRegisterAgent;
