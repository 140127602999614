// src/pages/FailedJobs.js

import React from 'react';
import { Layout, Row, Col, Card, Spin, Typography } from 'antd';
import {
  FileExclamationOutlined,
  DatabaseOutlined,
} from '@ant-design/icons';
import useFailedJobsData from '../hooks/useFailedJobsData';
import JobListForFailedJobs from '../components/JobListForFailedJobs';
import FailedItemsTable from '../components/FailedItemsTable';

const { Content } = Layout;
const { Title } = Typography;

const FailedJobs = () => {
  const {
    projectNames,
    getJobsByProjectName,
    loading,
  } = useFailedJobsData();

  // Find the selected job's items
  // const selectedJob = jobs.find((job) => job.job_id === selectedJobId);
  // const items = selectedJob ? selectedJob.items : [];
  // Only access items after confirming selectedJob exists
  // const selectedProject = selectedJob ? selectedJob.items[0]?.project_name : null;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content
        style={{
          padding: 0, // Ensure no extra padding
          background: '#f0f2f5',
          overflow: 'hidden', // Adjust overflow if necessary
        }}
      >
        <div style={{ padding: '24px' }}>
          <Title level={2}>
            <FileExclamationOutlined /> Activity Logs
          </Title>
          {loading ? (
            <Spin size="large" />
          ) : (
            <Row gutter={[32, 32]}>
              {/* Job List */}
              <Col xs={24}>
                <Card
                  // title="Jobs"
                  style={{ height: '100%',  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'}
                  
                
                }
                  bodyStyle={{ padding: 0 }}
                >
                  <JobListForFailedJobs
                    projectNames={projectNames}
                    getJobsByProjectName={getJobsByProjectName}
                  />
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default FailedJobs;
