// src/components/ProjectLayout.js

import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const ProjectLayout = ({ children }) => (
  <div style={{ padding: '24px', height: '100vh'}}>
    <Layout>
      <Content style={{ padding: '24px', backgroundColor:'#fff'}}>
        {children}
      </Content>
    </Layout>
  </div>
);

export default ProjectLayout;
