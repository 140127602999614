// src/hooks/useJobDetails.js

import { useState, useEffect } from 'react';
import { fetchJobDetails } from '../utils/jobApi';
import { message } from 'antd';

const useJobDetails = (jobId, setFormFields, getVerificationProgress) => {
  const [jobDetails, setJobDetails] = useState(null);
  const [verificationData, setVerificationData] = useState({
    total: 0,
    verified: 0,
    unverified: 0,
    percent: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadJobDetails = async () => {
      try {
        const data = await fetchJobDetails(jobId);
        setJobDetails(data);

        const verification = getVerificationProgress(data.image_verify);
        setVerificationData(verification);

        // Extract output data for the first image (index 0)
        if (data.job_output && data.job_output.length > 0) {
          const firstOutputObj = data.job_output[0];
          const outputDataKeys = Object.keys(firstOutputObj);
          const outputDataKey = outputDataKeys.length > 0 ? outputDataKeys[0] : null;
          if (outputDataKey) {
            const firstOutput = firstOutputObj[outputDataKey] || {};
            setFormFields(firstOutput, data.columns);
          } else {
            setFormFields({}, data.columns);
          }
        } else {
          setFormFields({}, data.columns);
        }
      } catch (error) {
        message.error('Failed to load job details');
      } finally {
        setLoading(false);
      }
    };

    loadJobDetails();
  }, [jobId, setFormFields, getVerificationProgress]);

  return { jobDetails, setJobDetails, verificationData, loading };
};

export default useJobDetails;
