// src/components/BreadcrumbComponent.js

import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import {
  ProjectOutlined,
  FolderOpenOutlined,
  FileTextOutlined,
} from '@ant-design/icons';

const BreadcrumbComponent = ({ projectId, projectName, jobName }) => (
  <Breadcrumb style={{ marginBottom: '16px' }}>
    <Breadcrumb.Item>
      <Link to="/project-management">
        <ProjectOutlined /> Projects
      </Link>
    </Breadcrumb.Item>
    {projectName && (
      <Breadcrumb.Item>
        <Link to={`/project-management/`}>
          <FolderOpenOutlined /> {projectName}
        </Link>
      </Breadcrumb.Item>
    )}
    {jobName && (
      <Breadcrumb.Item>
        <FileTextOutlined /> {jobName}
      </Breadcrumb.Item>
    )}
  </Breadcrumb>
);

export default BreadcrumbComponent;
