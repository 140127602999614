// src/utils/failedJobs.js

import axios from 'axios';
import { getAccessToken } from './auth';
import { API_URL } from '../utils/jobApi';


export const fetchFailedItems = async () => {
  const useDummyData = false; // Set this to false when API is ready

  if (useDummyData) {
    // Return dummy data
    const dummyData = [
      
    ]

    return dummyData;
  } else {
    // Fetch data from API
    try {
      const token = getAccessToken();

      if (!token) {
        throw new Error('User is not authenticated. Please log in.');
      }

      const response = await axios.post(`${API_URL}/get_registered_data`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data; // Ensure API returns data in the correct format
    } catch (error) {
      console.error('Error fetching failed items:', error);
      throw error;
    }
  }
};
