// src/pages/LoginPage.js
import React, { useState } from 'react';
import { Form, Input, Button, message, Card, Space, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { authenticateUser } from '../utils/auth';  // Import the auth helper

const LoginPage = ({ setIsAuthenticated, setRole }) => {
  const [loading, setLoading] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);  // Control the visibility of the spinner
  const navigate = useNavigate();

  // Async function to handle the login process
  const onFinish = async (values) => {
    setLoading(true);  // Start loading spinner

    // Show the spinner for 2 seconds before starting the login process
    setShowSpinner(true);

    setTimeout(async () => {
      try {
        // Await the result of the authentication process
        const { success, role, message: loginMessage } = await authenticateUser(values.username, values.password);

        if (success && role) {
          message.success('Login successful');
          setIsAuthenticated(true);  // Update the authentication state
          setRole(role);  // Set the role
          navigate('/dashboard');
        } else {
          // Specific message for invalid credentials
          message.error(loginMessage || 'Invalid credentials');
        }
      } catch (error) {
        // Handle any unexpected errors (network, server errors, etc.)
        console.error('Login error:', error);
        message.error('An unexpected error occurred. Please try again.');
      } finally {
        setLoading(false);  // Stop loading spinner
        setShowSpinner(false);  // Hide spinner
      }
    }, 100);
  };

  return (
    <div style={styles.container}>
      <Spin spinning={showSpinner} tip="Logging in..." size="large">  {/* Spin component */}
        <Card style={styles.card} bordered={false}>
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            {/* Company logo */}
            <img src="favicon-black.png" alt="Company Logo" style={styles.logo} />

            {/* Login form */}
            <Form name="login_form" layout="vertical" onFinish={onFinish}>
              <Form.Item
                name="username"
                label="Username"
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Input size="large" placeholder="Enter your username" />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password size="large" placeholder="Enter your password" />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}  // Disable button when loading
                  block
                  size="large"
                  style={styles.button}
                >
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </Space>
        </Card>
      </Spin>
    </div>
  );
};

// Styling for the page
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f2f5',
  },
  card: {
    width: 400,
    padding: '40px 20px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    backgroundColor: '#fff',
  },
  logo: {
    display: 'block',
    margin: '0 auto',
    height: 80,
  },
  button: {
    backgroundColor: '#1890ff',
    borderColor: '#1890ff',
    fontWeight: 'bold',
  },
};

export default LoginPage;
