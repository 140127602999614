// src/hooks/useSaveJobOutput.js

import { useState } from 'react';
import { saveJobOutput } from '../utils/jobApi';
import { message } from 'antd';

/**
 * Custom hook to handle saving job output.
 * @returns {Object} - Contains the save function and loading state.
 */
const useSaveJobOutput = () => {
  const [loading, setLoading] = useState(false);

  /**
   * Saves the job output by calling the API.
   * @param {string} jobId - The ID of the job.
   * @param {object} jobOutput - The job output data to save.
   * @returns {Promise<void>}
   */
  const saveOutput = async (jobId, jobOutput) => {
    setLoading(true);
    try {
      await saveJobOutput(jobId, jobOutput);
      message.success('Job output saved successfully');
    } catch (error) {
      message.error(error.message || 'Failed to save job output');
      throw error; // Re-throw to allow further handling if needed
    } finally {
      setLoading(false);
    }
  };

  return { saveOutput, loading };
};

export default useSaveJobOutput;
