// src/components/InviteAgentsForm.js

import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Typography, Space, Select, Row, Col } from 'antd';
import { message } from 'antd';
import { PlusOutlined, CopyOutlined } from '@ant-design/icons';
import useInviteAgent from '../hooks/useInviteAgent';
import useCopyToClipboard from '../hooks/useCopyToClipboard';
import { getUserRole } from '../utils/auth';

const { Text } = Typography;
const { Option } = Select;

/**
 * Component for inviting a new agent.
 * Renders a form to input the username and handles submission.
 * @param {Function} onClose - Function to close the modal after explicit user action.
 */
const InviteAgentsForm = ({ onClose }) => {
  const [form] = Form.useForm();
  const [username, setUsername] = useState('');
  const userRole = getUserRole();
  const [userInviteRole, setUserInviteRole] = useState('agent');
  
  const roleOptions = userRole === 'supervisor' 
      ? ['supervisor', 'agent'] 
      : ['admin', 'supervisor', 'agent'];

  const { loading, registrationToken, success, messageText, handleInvite } = useInviteAgent();
  const [copied, copy] = useCopyToClipboard();

  const onFinish = (values) => {
    handleInvite(values.username, values.role);
    setUsername(values.username);
    setUserInviteRole(values.role);
  };

  const invitationURL = registrationToken
    ? `${window.location.origin}/invitation?token=${registrationToken}&username=${username}&role=${userInviteRole}`
    : '';

  const handleCopy = () => {
    if (invitationURL) {
      copy(invitationURL);
    }
  };

  useEffect(() => {
    if (messageText) {
      success ? message.success(messageText) : message.error(messageText);
      // Removed automatic modal closure
      // if (success && onClose) {
      //   onClose(); // Close the modal upon successful invitation
      // }
    }
  }, [messageText, success]);

  return (
    <Card bordered={true} style={{ padding: '20px' }}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        {/* Wrap username and role inputs in a Row with responsive Col spacing */}
        <Row gutter={16}>
          <Col xs={24} sm={16}>
            <Form.Item
              label="Username/Email"
              name="username"
              rules={[
                { required: true, message: 'Please input the username!' },
                { min: 3, message: 'Username must be at least 3 characters.' }
              ]}
            >
              <Input placeholder="Enter new agent username/email" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              label="Role"
              name="role"
              rules={[
                { required: true, message: 'Please select a role!' }
              ]}
            >
              <Select placeholder="Assign a role">
                {roleOptions.map((role) => (
                  <Option key={role} value={role}>
                    {role.charAt(0).toUpperCase() + role.slice(1)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Submit Button */}
        <Form.Item style={{ textAlign: 'center' }}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<PlusOutlined />}
            loading={loading}
            block
          >
            Invite
          </Button>
        </Form.Item>
      </Form>

      {/* Display Invitation URL upon successful invitation */}
      {success && registrationToken && (
        <Card type="inner" title="Invitation Link" style={{ marginTop: '16px' }}>
          <Space direction="vertical">
            <Text copyable={{ text: invitationURL, tooltips: ['Copy URL', 'Copied!'] }}>
              {invitationURL}
            </Text>
            <Button
              type="default"
              icon={<CopyOutlined />}
              onClick={handleCopy}
              disabled={!invitationURL}
            >
              {copied ? 'Copied!' : 'Copy URL'}
            </Button>
          </Space>
        </Card>
      )}
    </Card>
  );
};

export default InviteAgentsForm;
