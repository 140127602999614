// src/components/SelectJobs.js

import React from 'react';
import { Card, Space, Select, Table, Input, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Search } = Input;
const { Text } = Typography;

/**
 * SelectJobs Component
 * Allows filtering and selecting jobs from the list.
 */
const SelectJobs = ({
  projectsData,
  selectedProjects,
  handleProjectSelect,
  verificationFilter,
  handleVerificationFilterChange,
  handleJobSearchChange,
  filteredJobs,
  handleJobRowClick,
  rowClassName,
  jobColumns,
}) => {
  return (
    <Card
      title={
        <Space
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text strong>Select Jobs</Text>
          <Space>
            {/* Verification Status Filter */}
            <Select
              value={verificationFilter}
              onChange={handleVerificationFilterChange}
              style={{ width: 150 }}
              options={[
                { label: 'All', value: 'all' },
                { label: 'Verified', value: 'verified' },
                { label: 'Unverified', value: 'unverified' },
              ]}
            />

            {/* Instant Search Input */}
            <Search
              placeholder="Search by Job or Agent"
              allowClear
              onChange={handleJobSearchChange}
              style={{ width: 200 }}
              prefix={<SearchOutlined />}
            />
          </Space>
        </Space>
      }
      bordered={true}
      style={{ height: '100%' }}
    >
      {/* Project Multiselect */}
      <Space direction="vertical" style={{ width: '100%', marginBottom: '16px' }}>
        <Select
          mode="multiple"
          allowClear
          placeholder="Filter by Projects"
          onChange={handleProjectSelect}
          style={{ width: '100%' }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          notFoundContent="No projects found"
          maxTagCount="responsive"
        >
          {projectsData.map((project) => (
            <Option key={project.id} value={project.id}>
              {project.name}
            </Option>
          ))}
        </Select>
      </Space>

      {/* Jobs Table */}
      <Table
        dataSource={filteredJobs}
        columns={jobColumns}
        pagination={{ pageSize: 10 }}
        rowKey="id"
        size="middle"
        scroll={{ y: 300 }}
        onRow={(record) => ({
          onClick: () => handleJobRowClick(record.id),
        })}
        rowClassName={rowClassName}
      />
    </Card>
  );
};

export default SelectJobs;
