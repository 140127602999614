// src/components/JobDetails.js

import React, { useState, useEffect } from 'react';
import { Descriptions, Spin, message, Tag, Progress } from 'antd';
import { fetchJobDetails } from '../utils/jobApi';
import {
  ProjectOutlined,
  FileTextOutlined,
  TagsOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';

import useProjectData from '../hooks/useProjectData'; // Import the hook



const JobDetails = ({ jobId, detailed }) => {
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(false);

  const [projectId, setProjectId] = useState(null);
  const [verificationData, setVerificationData] = useState({
    total: 0,
    verified: 0,
    unverified: 0,
    percent: 0,
  });

  // Initialize the hook with projectId
  const { getVerificationProgress } = useProjectData(projectId);

  // Fetch job details
  useEffect(() => {
    const loadJobDetails = async () => {
      setLoading(true);
      try {
        const jobData = await fetchJobDetails(jobId);
        setJob(jobData);
        setProjectId(jobData.project_id);

        const verification = getVerificationProgress(jobData.image_verify);
        setVerificationData(verification);

      } catch (error) {
        message.error('Failed to load job details');
      } finally {
        setLoading(false);
      }
    };

    if (jobId) {
      loadJobDetails();
    }
  }, [jobId, getVerificationProgress]);

  if (loading) {
    return <Spin />;
  }

  if (!job) {
    return <p>No job selected.</p>;
  }

  return (
    <Descriptions bordered column={1} size={detailed ? 'middle' : 'small'}>
      <Descriptions.Item label={<span><ProjectOutlined /> Name</span>}>
        {job.name}
      </Descriptions.Item>
      <Descriptions.Item label={<span><FileTextOutlined /> Description</span>}>
        {job.description}
      </Descriptions.Item>
      <Descriptions.Item label="Status">
        {job.status}
      </Descriptions.Item>
      <Descriptions.Item label={<span><TagsOutlined /> Columns</span>}>
        {job.columns.map((column) => (
          <Tag key={column} color="blue">{column}</Tag>
        ))}
      </Descriptions.Item>
      <Descriptions.Item label={<span><ClockCircleOutlined /> Created At</span>}>
        {new Date(job.created_at).toLocaleString()}
      </Descriptions.Item>

      <Descriptions.Item label={<span>Verification Progress:</span>}>
        <Progress
          percent={Math.round(verificationData.percent)}
          status={verificationData.unverified === 0 && verificationData.total > 0 ? 'success' : 'active'}
          size="small"
        />
        <div style={{ fontSize: '12px', marginTop: '4px' }}>
          {verificationData.verified} / {verificationData.total} Verified
        </div>
      </Descriptions.Item>

      {/* Add other job details as needed */}
    </Descriptions>
  );
};

export default JobDetails;
