// src/components/SelectedJobs.js

import React from 'react';
import { Card, List, Empty, Button, Tag, Space, Typography } from 'antd';
import { CheckCircleOutlined, CloseOutlined, ProjectOutlined } from '@ant-design/icons';

const { Text } = Typography;

const SelectedJobs = ({ selectedJobs, getJobById, handleRemoveSelectedJob }) => {
  return (
    <Card title="Selected Jobs" style={{ height: '100%' }}>
      {selectedJobs.length > 0 ? (
        <List
          dataSource={selectedJobs}
          renderItem={(jobId) => {
            const job = getJobById(jobId);

            // Add a check to handle undefined job
            if (!job) {
              console.warn(`Job not found for jobId: ${jobId}`);
              return null;
            }

            return (
              <List.Item
                key={jobId}
                actions={[
                  <Button
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={() => handleRemoveSelectedJob(jobId)}
                  />,
                ]}
                style={{
                  background: '#fff',
                  borderRadius: '4px',
                  marginBottom: '8px',
                }}
              >
                <List.Item.Meta
                  avatar={
                    <CheckCircleOutlined
                      style={{ color: '#52c41a', fontSize: '20px' }}
                    />
                  }
                  title={<div>
                    <Text strong>{job.name} &nbsp; &nbsp;</Text>
                    <Space>
                      <Tag color="geekblue">
                        <ProjectOutlined style={{ marginRight: '4px' }} />
                        {job.projectName}
                      </Tag>
                    </Space>
                </div>}
                />
              </List.Item>
            );
          }}
          style={{
            maxHeight: '440px',
            overflowY: 'auto',
            padding: '8px',
          }}
        />
      ) : (
        <Empty description="No jobs selected" />
      )}
    </Card>
  );
};

export default SelectedJobs;
