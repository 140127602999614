import { CarryOutOutlined, EyeOutlined, CopyOutlined, ForwardOutlined, RightOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { List, Table, Typography, Modal, Button, message, Input, Row, Col } from 'antd';
import { API_URL } from '../utils/jobApi';

const { Text } = Typography;

const JobListForFailedJobs = ({ projectNames, getJobsByProjectName }) => {
  const [selectedProject, setSelectedProject] = useState(projectNames[0] || null);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [originalResponses, setOriginalResponses] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 5 });

  const columnsToIgnore = ['created_at'];

  useEffect(() => {
    const loadJobs = async () => {
      if (selectedProject) {
        const jobs = await getJobsByProjectName(selectedProject);
        setFilteredJobs(jobs);
      }
    };

    loadJobs();
  }, [selectedProject, getJobsByProjectName]);

  const onSelectProject = async (projectName) => {
    if (selectedProject === projectName) {
      setSelectedProject(null);
      setFilteredJobs([]);
      setSelectedJob(null);
      setSelectedImage(null);
      setOriginalResponses([]);
    } else {
      setSelectedProject(projectName);
      const jobs = await getJobsByProjectName(projectName);
      setFilteredJobs(jobs);
      setSelectedJob(null);
      setSelectedImage(null);
      setOriginalResponses([]);
    }
  };

  const onJobClick = (job) => {
    setSelectedJob(job);
    if (job.media_data && job.media_data.length > 0) {
      const image = job.media_data[0];
      setSelectedImage(image);
      setOriginalResponses(image.responses || []);
      setPagination({ current: 1, pageSize: pagination.pageSize }); // Reset pagination
    } else {
      setSelectedImage(null);
    }
  };

  const onImageClick = (image) => {
    setSelectedImage(image);
    setOriginalResponses(image.responses || []);
    setPagination({ current: 1, pageSize: pagination.pageSize }); // Reset pagination
  };

  const showModal = (data) => {
    setModalData(data);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setModalData(null);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(JSON.stringify(modalData, null, 2));
    message.success("Data copied to clipboard!");
  };

  const handlePreview = (image) => {
    setPreviewImage(image);
  };

  const handleSearch = (searchValue) => {
    const filteredOutput = originalResponses.filter((item) => {
      const userName = item.request?.name?.toLowerCase() || "";
      const userMobile = item.request?.mobile_number?.toString().toLowerCase() || "";
      return userName.includes(searchValue) || userMobile.includes(searchValue);
    });

    setSelectedImage((prevImage) => ({
      ...prevImage,
      responses: filteredOutput,
    }));
    setPagination({ current: 1, pageSize: pagination.pageSize }); // Reset pagination to first page on search
  };

  const columns = selectedImage && selectedImage.responses && selectedImage.responses.length > 0
    ? [
      {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        render: (_, record) => record.request?.name || 'N/A',
      },
      ...Object.keys(selectedImage.responses[0])
        .filter((key) => !columnsToIgnore.includes(key))
        .map((key) => ({
          title: key.replace(/_/g, ' ').toUpperCase(),
          dataIndex: key,
          key: key,
          render: (value) =>
            typeof value === 'object' ? (
              <Button icon={<EyeOutlined />} onClick={() => showModal(value)} />
            ) : (
              value
            ),
        })),
    ]
    : [];

  const paginatedResponses = selectedImage?.responses.slice(
    (pagination.current - 1) * pagination.pageSize,
    pagination.current * pagination.pageSize
  ) || [];

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <div style={{ flex: 0.3, overflowY: 'auto', padding: '20px', borderRight: '1px solid #ddd' }}>
        <List
          dataSource={projectNames}
          renderItem={(projectName) => (
            <div key={projectName}>
              <List.Item
                style={{
                  backgroundColor: projectName === selectedProject ? '#CFD8DC' : 'transparent',
                  cursor: 'pointer',
                  padding: '10px',
                }}
                onClick={() => onSelectProject(projectName)}
              >
                <List.Item.Meta
                  avatar={<CarryOutOutlined style={{ fontSize: '20px', }} />}
                  title={projectName}
                />
              </List.Item>

              {projectName === selectedProject && filteredJobs.length > 0 && (
                <div style={{ paddingLeft: '48px', marginBottom: '10px' }}>
                  <List
                    dataSource={filteredJobs}
                    renderItem={(job) => (
                      <List.Item
                        key={job.job_id}
                        style={{
                          backgroundColor: job === selectedJob ? '#ECEFF1' : 'transparent',
                          marginTop: '10px',
                          padding:'10px',
                          cursor: 'pointer',
                        }}
                        onClick={() => onJobClick(job)}
                      >
                        <List.Item.Meta
                          title={job.job_name}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              )}
            </div>
          )}
        />
      </div>

      <div title="Job Details" style={{ flex: 0.7, overflowY: 'auto', padding: '20px' }}>
        {selectedImage ? (
          <div>
            <div style={{ marginBottom: '10px' }} title="Image Preview">
              <h3 style={{ marginBottom: '10px' }}>
                Select an Image
              </h3>

              <ul style={{ listStyleType: 'none', padding: 0, marginBottom: '15px', display: 'flex', gap: '10px' }}>
                {selectedJob?.media_data.map((image) => (
                  <li
                    key={image.media_index}
                    style={{
                      cursor: 'pointer',
                      color: selectedImage.media_index === image.media_index ? '#1890ff' : 'inherit',
                      padding: '8px 12px', // Adds padding for better click area
                      backgroundColor: '#fff', // White background
                      boxshadow: '0',
                      border:'1px solid #ddd',
                    }}
                    onClick={() => onImageClick(image)}
                 
                  >
                    {image.media_index}
                  </li>
                ))}
              </ul>
            </div>

            <div style={{ marginTop: '10px' }}>
              <Row gutter={16} style={{ marginBottom: 10 }}>
                <Col span={20}>
                  <Input.Search
                    placeholder="Search name and mobile number"
                    onChange={(e) => handleSearch(e.target.value.toLowerCase())}
                    style={{ width: '50%', marginBottom: '10px' }}
                  />
                </Col>
                <Col span={4}>
                  <Button onClick={() => handlePreview(selectedImage)} disabled={!selectedImage} icon={<EyeOutlined />}>
                    Show Image
                  </Button>
                </Col>
              </Row>

              <Table
                columns={columns}
                dataSource={paginatedResponses} // Use paginated responses
                rowKey={(record) => record.name}
                pagination={{
                  position: ['bottomLeft'],
                  showSizeChanger: true,
                  pageSize: pagination.pageSize,
                  current: pagination.current,
                  total: selectedImage?.responses.length || 0, // Total items for pagination
                  onChange: (page, pageSize) => {
                    setPagination({ current: page, pageSize });
                  },
                  onShowSizeChange: (current, size) => {
                    setPagination({ current: 1, pageSize: size }); // Reset to first page
                  },
                }}
                scroll={{ x: "max-content" }}
              />
            </div>

            <Modal
              visible={!!previewImage}
              footer={null}
              onCancel={() => setPreviewImage(null)}
              title="Image Preview"
            >
              {previewImage && (
                <img
                  src={`${API_URL}/${previewImage.media_key}`}
                  style={{ width: '100%', maxHeight: '500px', objectFit: 'contain' }}
                />
              )}
            </Modal>
          </div>
        ) : (
          <Text>Select a job to see image details and outputs here.</Text>
        )}
      </div>

      <Modal
        title="Detailed View"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="copy" icon={<CopyOutlined />} onClick={handleCopy}>
            Copy
          </Button>,
          <Button key="close" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        <pre>{JSON.stringify(modalData, null, 2)}</pre>
      </Modal>
    </div>
  );
};

export default JobListForFailedJobs;
