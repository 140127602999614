// src/hooks/useAgentDashboardData.js

import { useState, useEffect } from 'react';
import { message } from 'antd';
import {
  fetchAgentAssignedJobs,
  fetchAgentVerifiedJobs,
} from '../utils/agentDashboardApi';

const useAgentDashboardData = () => {
  const [assignedJobs, setAssignedJobs] = useState([]);
  const [verifiedJobs, setVerifiedJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAgentDashboardData = async () => {
      try {
        // Retrieve agent username from localStorage or context
        const username = localStorage.getItem('username'); // Adjust based on your auth implementation

        if (!username) {
          throw new Error('Agent username not found.');
        }

        // Fetch assigned and verified jobs concurrently
        const [assignedJobsData, verifiedJobsData] = await Promise.all([
          fetchAgentAssignedJobs(username),
          fetchAgentVerifiedJobs(username),
        ]);

        setAssignedJobs(assignedJobsData || []);

        // For verified jobs, the response contains { total_verified_jobs, jobs }
        setVerifiedJobs(verifiedJobsData.jobs || []);
      } catch (err) {
        console.error('Error fetching agent dashboard data:', err);
        setError('Failed to load assigned jobs. Please try again later.');
        message.error('Failed to load assigned jobs');
      } finally {
        setLoading(false);
      }
    };

    fetchAgentDashboardData();
  }, []);

  return {
    assignedJobs,
    verifiedJobs,
    loading,
    error,
  };
};

export default useAgentDashboardData;
