import React, { useState } from 'react';
import { Form, Card, Row, Col, Select, Table, Button, Spin, Input } from 'antd';
import { SaveOutlined, PlusOutlined, MinusOutlined, SyncOutlined } from '@ant-design/icons';

const OutputForm = ({
  form,
  handleSave,
  handleSchoolSearch,
  selectedSchool,
  setSelectedSchool,
  setSchoolOptions,
  setSearchValue,
  schoolOptions,
  searchValue,
  setFirstDropdownValue,
  secondDropdownOptions,
  setSecondDropdownOptions,
  optionsData,
  tableData,
  jobColumns,
  verifying,
  handleVerify,
  saving,
  handleSaving,
  loadingSync,
  handleAddRow,
  handleSyncClick,
  handleRemoveRow,
}) => {
  // State to manage the current action (Verify or Save)
  const [isVerifying, setIsVerifying] = useState(false);

  const columns = [
    ...jobColumns.map((column) => ({
      title: column,
      dataIndex: column,
      key: column,
      render: (text, record) => (
        <Form.Item
          name={[record.key, column]}
          style={{ margin: 0 }}
          rules={[{ required: false }]} // Always set this as false for table fields
        >
          <Input />
        </Form.Item>
      ),
    })),
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record, index) => (
        <div>
          <Button
            type="link"
            icon={<PlusOutlined />}
            onClick={() => handleAddRow(index, Object.keys(record))}
          />
          <Button
            type="link"
            icon={<MinusOutlined />}
            onClick={() => handleRemoveRow(index)}
            disabled={tableData.length === 1}
          />
        </div>
      ),
    },
  ];

  const handleFirstDropdownChange = (selectedValue) => {
    setFirstDropdownValue(selectedValue);
    const selectedScheme = optionsData.packageSchemes.find(item => item.name === selectedValue);
    const packages = selectedScheme ? selectedScheme.packages : []; // Ensure it's an array
    setSecondDropdownOptions(Array.isArray(packages) ? packages : []); // Set as array
  };

  const handleVerifyClick = async () => {
    setIsVerifying(true);
    try {
      await handleVerify();
    } finally {
      setIsVerifying(false);
    }
  };

  return (
    <Form form={form} layout="vertical">
      <Card style={{ marginBottom: '24px', padding: '0px' }}>
        <Row gutter={[30, 30]}>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Form.Item
              name="schoolName"
              label="School Name"
              rules={[
                {
                  required: isVerifying,
                  message: 'Please select a school name!',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a school"
                onSearch={handleSchoolSearch}
                onChange={(value) => {
                  setSelectedSchool(value);
                  form.setFieldsValue({ schoolName: value });
                  setSchoolOptions([]);
                  setSearchValue('');
                }}
                filterOption={true}
                notFoundContent={null}
                options={schoolOptions}
                defaultActiveFirstOption={true}
                showArrow={false}
                value={selectedSchool}
                allowClear
                searchValue={searchValue}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6} lg={6}>
            <Form.Item
              name="scholarshipScheme"
              label="Scholarship Scheme"
              rules={[
                {
                  required: isVerifying,
                  message: 'Please select a scholarship scheme!',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a scholarship scheme"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={handleFirstDropdownChange}
              >
                {optionsData.scholarshipSchemes.map((scheme) => (
                  <Select.Option key={scheme} value={scheme}>
                    {scheme}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={13} md={6} lg={6}>
            <Form.Item
              name="packageSchemes"
              label="Package Schemes"
              rules={[
                {
                  required: isVerifying,
                  message: 'Please select a package scheme!',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a package scheme"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                options={secondDropdownOptions.map(scheme => ({ value: scheme.id, label: scheme.admin_display_name }))} // Assuming the structure of your options
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Table
        dataSource={tableData}
        columns={columns}
        pagination={false}
        bordered
        scroll={{
          y: 440, // Set the height (e.g., 400px for vertical scrolling)
          x: '100%', // Set the width for horizontal scrolling
        }}
      />
      <Form.Item>
        <div style={{ display: 'flex', gap: '15px', marginTop: '15px' }}>
          <div style={{ padding: '5px' }}>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              htmlType="button"
              loading={saving}
              disabled={tableData.length === 0}
              onClick={handleSaving}
            >
              {saving ? <Spin size="small" /> : 'Save'}
            </Button>
          </div>
          <div style={{ padding: '5px' }}>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              htmlType="button"
              loading={verifying}
              disabled={tableData.length === 0}
              onClick={handleVerifyClick}
            >
              {verifying ? <Spin size="small" /> : 'Send to AG'}
            </Button>
          </div>
        </div>
      </Form.Item>
    </Form>
  );
};

export default OutputForm;
