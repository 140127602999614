import axios from 'axios';
import { API_URL } from '../utils/jobApi';
// Function to authenticate user using an API
export const authenticateUser = async (username, password) => {
  try {
    // Prepare form data as URL-encoded
    const formData = new URLSearchParams();
    formData.append('username', username);
    formData.append('password', password);

    // Make the API request with URL-encoded form data
    console.log(API_URL)
    const response = await axios.post(`${API_URL}/login`, formData, {
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    // Extract the necessary data from the response
    // const data = response.data;

    // console.log(data);

    // Check if the login is successful and contains an access token
    if (response.data.role && response.data.access_token) {
      // Store the JWT token in localStorage for future requests
      localStorage.setItem('accessToken', response.data.access_token);  // Store token
      localStorage.setItem('userRole', response.data.role);
      localStorage.setItem('username', response.data.username);  // Optional: Store username
      localStorage.setItem('user_id', response.data.id);  

      return { success: true, role: response.data.role, username: response.data.username };
    } else {
      return { success: false, message: 'Invalid credentials' };
    }
  } catch (error) {
    console.error('Login error:', error);
    return { success: false, message: 'An error occurred while logging in' };
  }
};

// Function to get the stored access token from localStorage
export const getAccessToken = () => {
  return localStorage.getItem('accessToken');  // Retrieve the stored token
};

// Function to get the user role from localStorage
export const getUserRole = () => {
  return localStorage.getItem('userRole');  // Retrieve the stored user role
};

// Function to get the username from localStorage
export const getUsername = () => {
  return localStorage.getItem('username');  // Retrieve the stored username if needed
};

export const getUserId = () => {
  return localStorage.getItem('user_id');  // Retrieve the stored username if needed
};

// Function to logout user by clearing localStorage
export const logoutUser = () => {
  localStorage.removeItem('accessToken');  // Clear the stored access token
  localStorage.removeItem('userRole');  // Clear the stored user role
  localStorage.removeItem('username');  // Optional: Clear stored username if needed
};
