// src/hooks/useFormManager.js

import { useState, useCallback } from 'react';

const useFormManager = (form) => {
  const [tableData, setTableData] = useState([]);

  const setFormFields = useCallback(
    (outputData, columns) => {
      form.resetFields();
      const formData = {};

      formData.schoolName = outputData.schoolName || '';
      formData.scholarshipScheme = outputData.scholarshipScheme || '';
      formData.subject = outputData.subject || '';
      formData.district = outputData.district || '';
      formData.principalName = outputData.principalName || '';

      const tableDataArray = [];

      for (let key in outputData) {
        const value = outputData[key];
        if (typeof value === 'object' && value !== null) {
          const rowData = { key };
          columns.forEach((column) => {
            rowData[column] = value[column] || '';
          });
          tableDataArray.push(rowData);
          formData[key] = value;
        } else {
          formData[key] = value;
        }
      }
      form.setFieldsValue(formData);
      setTableData(tableDataArray);
    },
    [form]
  );

  const handleAddRow = (index, columns) => {
    const newKey = `new_${Date.now()}`;
    let newRow = {};
    columns.forEach((column) => {
      newRow[column] = '';
    });
    newRow['key'] = newKey;
    const newData = [...tableData];
    newData.splice(index + 1, 0, newRow);
    setTableData(newData);
    form.setFieldsValue({
      [newKey]: {},
    });
  };

  const handleRemoveRow = (index) => {
    const newData = [...tableData];
    const removedRow = newData.splice(index, 1);
    setTableData(newData);
    const removedRowKey = removedRow[0].key;
    const fieldsValue = form.getFieldsValue();
    delete fieldsValue[removedRowKey];
    form.setFieldsValue(fieldsValue);
  };

  return { tableData, setFormFields, handleAddRow, handleRemoveRow };
};

export default useFormManager;
