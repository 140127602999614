// src/hooks/useProjectData.js

import { useState, useEffect, useCallback } from 'react';
import { message } from 'antd';
import {
  fetchProjectDetails,
  fetchJobs,
  performAction,
  deleteJob,
} from '../utils/jobApi';
import { getOptionsData, submitDefaults } from '../utils/project'; // Import the options data function

const useProjectData = (projectId) => {
  const [projectName, setProjectName] = useState('');
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  // State for options data
  const [optionsData, setOptionsData] = useState({
    schoolNames: [],
    scholarshipSchemes: [],
    subjects: [],
    principals: [],
    districts: [],
  });

  // Fetch options data
  useEffect(() => {
    const loadOptionsData = async () => {
      try {
        const data = await getOptionsData();
        setOptionsData(data);
      } catch (error) {
        message.error('Failed to load options data');
      }
    };

    loadOptionsData();
  }, []);

  // Fetch project details
  useEffect(() => {
    if (!projectId) {
      setProjectName('');
      setJobs([]);
      return;
    }

    const loadProjectDetails = async () => {
      try {
        const project = await fetchProjectDetails(projectId);
        setProjectName(project.name);
      } catch (error) {
        message.error('Failed to load project details');
      }
    };
    loadProjectDetails();
  }, [projectId]);

  const handleDefaultsSubmit = async (values) => {
    try {
      // Call submitDefaults function from project.js
      const response = await submitDefaults(values);
      if (response.success){
        return true
      }
      // message.success(response.message || 'Defaults submitted successfully!');
    } catch (error) {
      message.error('Failed to submit defaults');
    }
  };

  // Fetch jobs
  const loadJobs = useCallback(async () => {
    if (!projectId) {
      setJobs([]);
      return;
    }

    setLoading(true);
    try {
      const response = await fetchJobs(projectId);
      setJobs(response);
    } catch (error) {
      message.error('Failed to load jobs');
    } finally {
      setLoading(false);
    }
  }, [projectId]);

  useEffect(() => {
    loadJobs();
  }, [loadJobs]);

  // Handle job creation
  const handleJobCreation = (newJob) => {
    setJobs((prevJobs) => [...prevJobs, newJob]);
  };

  // Handle job action
  const handleActionClick = async (jobId) => {
    try {
      await performAction(jobId);
      message.success('Action performed successfully!');
    } catch (error) {
      message.error('Failed to perform action');
    }
    loadJobs();
  };

  const handleRefreshClick = () => {
    loadJobs();  // Manual refresh of the table
  };

  // Handle job deletion
  const handleDeleteClick = async (jobId) => {
    try {
      await deleteJob(jobId);
      message.success('Job deleted successfully!');
      loadJobs();
    } catch (error) {
      message.error('Failed to delete job');
    }
  };

  const getVerificationProgress = useCallback((imageVerify) => {
    if (!Array.isArray(imageVerify)) {
      return { total: 0, verified: 0, unverified: 0, percent: 0 };
    }
    const total = imageVerify.length;
    const verified = imageVerify.filter((v) => v).length;
    const unverified = total - verified;
    const percent = total === 0 ? 0 : (verified / total) * 100;
    return { total, verified, unverified, percent };
  }, []); // Empty dependency array ensures it's only created once

  return {
    projectName,
    jobs,
    loading,
    optionsData, // Include optionsData in the return object
    handleJobCreation,
    handleActionClick,
    handleDeleteClick,
    handleRefreshClick,
    handleDefaultsSubmit,
    getVerificationProgress
  };
};

export default useProjectData;
