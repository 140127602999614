// src/utils/jobApi.js

import axios from 'axios';
import { getAccessToken } from './auth';
import { message } from 'antd';

// export const API_URL = '/api';
export const API_URL = '/api';


// API to fetch jobs for a project
export const fetchJobs = async (projectId) => {
  const response = await fetch(`${API_URL}/jobs/${projectId}`);
  
  if (!response.ok) throw new Error('Failed to fetch jobs');
  
  const data = await response.json();
  
  return data;
};

// API to create a job with images and track progress
export const createJobWithImages = async (projectId, formData, onUploadProgress) => {
    const token = getAccessToken();  // Get token from localStorage

    if (!token) {
      throw new Error('User is not authenticated. Please log in.');
    }

  const response = await axios.post(`${API_URL}/jobs/create-with-images/${projectId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${token}`,
    },
    onUploadProgress,  // Pass the progress function to track upload
  });
  if (response.status !== 200) {
    throw new Error('Failed to create job');
  }
  return response.data;
};

export const fetchProjectDetails = async (projectId) => {
  try {
    const response = await axios.get(`${API_URL}/projects/${projectId}`, {
      headers: {
        'accept': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching project details:', error);
    throw error;
  }
};

export const performAction = async (jobId) => {
  const response = await axios.post(`${API_URL}/jobs/${jobId}/action`);
  if (response.status !== 200) {
    throw new Error('Failed to perform action');
  }
  return response.data;
};

export const deleteJob = async (jobId) => {
  try {
    const response = await axios.delete(`${API_URL}/jobs/${jobId}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to delete job');
  }
};


// Fetch job details by job ID
export const fetchJobDetails = async (jobId) => {
  try {
    const token = getAccessToken();

    if (!token) {
      throw new Error('User is not authenticated. Please log in.');
    }

    const response = await axios.get(`${API_URL}/job/${jobId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching job details:', error);
    throw new Error('Failed to fetch job details');
  }
};

export const saveJobOutput = async (jobId, jobOutput) => {
  try {
    const token = getAccessToken();

    if (!token) {
      throw new Error('User is not authenticated. Please log in.');
    }

    const response = await axios.put(
      `${API_URL}/jobs/${jobId}/output`,
      { job_output: jobOutput }, // Wrap jobOutput inside job_output key
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error saving job output:', error);
    // Extract error message if available
    const errorMessage = error.response?.data?.detail || 'Failed to save job output';
    throw new Error(errorMessage);
  }
};

// Fetch total number of projects with job counts
export const fetchProjectsJobCounts = async () => {
  try {
    const token = getAccessToken();

    if (!token) {
      throw new Error('User is not authenticated. Please log in.');
    }

    const response = await axios.get(`${API_URL}/projects/jobs/count`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching projects job counts:', error);
    throw error;
  }
};

// Fetch total number of jobs
export const fetchTotalJobsCount = async () => {
  try {
    const token = getAccessToken();

    if (!token) {
      throw new Error('User is not authenticated. Please log in.');
    }

    const response = await axios.get(`${API_URL}/jobs/count`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching total jobs count:', error);
    throw error;
  }
};

// Fetch jobs assigned to a specific agent
export const fetchAgentJobs = async (agentUsername) => {
  try {
    const token = getAccessToken();

    if (!token) {
      throw new Error('User is not authenticated. Please log in.');
    }

    const response = await axios.get(`${API_URL}/agent/jobs/${agentUsername}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching agent jobs:', error);
    throw error;
  }
};

// Fetch total number of projects
export const fetchTotalProjectsCount = async () => {
  try {
    const token = getAccessToken();

    if (!token) {
      throw new Error('User is not authenticated. Please log in.');
    }

    const response = await axios.get(`${API_URL}/projects/count/total`, {
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching total projects count:', error);
    throw error;
  }
};

export const fetchAllAgents = async () => {
  try {
    const token = getAccessToken();

    if (!token) {
      throw new Error('User is not authenticated. Please log in.');
    }

    const response = await axios.get(`${API_URL}/agents/all`, {
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    
    // Assuming response.data is an array of agents
    return {
      agentJobsData: response.data,
      agentsCount: response.data.length,
    };
  } catch (error) {
    console.error('Error fetching agents:', error);
    throw error;
  }
};


export const verifyImage = async (token, requestBody) => {
  try {
    const token = getAccessToken();

    if (!token) {
      throw new Error('User is not authenticated. Please log in.');
    }
    const response = await axios.post(`${API_URL}/verify_image`, requestBody, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const registerStudents = async (token, verifyBody) => {
  try {
    const token = getAccessToken();

    if (!token) {
      throw new Error('User is not authenticated. Please log in.');
    }
    const response = await axios.post(
      `${API_URL}/register_students`,
      verifyBody,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveOutputData = async (token, saveOutputBody) => {
  try {
    const token = getAccessToken();

    if (!token) {
      throw new Error('User is not authenticated. Please log in.');
    }
    const response = await axios.post(
      `${API_URL}/jobs/saveOutput`,
      saveOutputBody,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const syncAgData= async () => {
  try {
    const token = getAccessToken();

    if (!token) {
      throw new Error('User is not authenticated. Please log in.');
    }
    const response = await axios.post(
      `${API_URL}/sync_agdata`,null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};