// src/utils/assignJobsUtils.js

// Utility functions for AssignJobs

/**
 * Get all jobs from the projects data.
 * @param {Array} projectsData - The list of projects.
 * @returns {Array} - The list of all jobs with project information.
 */
export const getAllJobs = (projectsData) => {
    let allJobs = [];
    projectsData.forEach((project) => {
      allJobs = [
        ...allJobs,
        ...project.jobs.map((job) => ({
          ...job,
          projectId: String(project.id), // Ensure projectId is a string
          projectName: project.name,
          id: String(job.id), // Ensure job id is a string
        })),
      ];
    });
    return allJobs;
  };
  
  export const getAgentById = (agentsData, agentId) => {
    const agent = agentsData.find((agent) => String(agent.id) === String(agentId));
    // if (!agent) {
    //   console.warn(`Agent not found for agentId: ${agentId}`);
    // }
    return agent;
  };
  
  export const getJobById = (allJobs, jobId) => {
    const job = allJobs.find((job) => String(job.id) === String(jobId));
    if (!job) {
      console.warn(`Job not found in getJobById for jobId: ${jobId}`);
    }
    return job;
  };