// src/pages/AgentDashboard.js

import React from 'react';
import { Layout, Typography, Card, Table, Spin, Tag, Tabs, Alert, Progress } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  ProjectOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import useAgentDashboardData from '../hooks/useAgentDashboardData';

const { Content } = Layout;
const { Title } = Typography;
const { TabPane } = Tabs;

// Helper function to calculate verification progress
const calculateVerificationProgress = (imageVerify) => {
  if (!Array.isArray(imageVerify)) {
    return { total: 0, verified: 0, unverified: 0, percent: 0 };
  }
  const total = imageVerify.length;
  const verified = imageVerify.filter((v) => v).length;
  const unverified = total - verified;
  const percent = total === 0 ? 0 : (verified / total) * 100;
  return { total, verified, unverified, percent };
};


const AgentDashboard = () => {
  const navigate = useNavigate();
  const { assignedJobs, verifiedJobs, loading, error } = useAgentDashboardData();

  // Define columns for the table
  const columns = [
    {
      title: 'Project',
      dataIndex: 'project_name',
      key: 'project_name',
      render: (text) => (
        <span>
          <ProjectOutlined /> {text}
        </span>
      ),
    },
    {
      title: 'Job Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        let color = 'default';
        let icon = null;
  
        switch (status) {
          case 'pending':
            color = 'warning';
            icon = <ExclamationCircleOutlined />;
            break;
          case 'in-progress':
            color = 'processing';
            icon = <SyncOutlined spin />;
            break;
          case 'completed':
          case 'verified':
            color = 'success';
            icon = <CheckCircleOutlined />;
            break;
          case 'failed':
            color = 'error';
            icon = <CloseCircleOutlined />;
            break;
          default:
            break;
        }
  
        return (
          <Tag icon={icon} color={color}>
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </Tag>
        );
      },
    },
    {
      title: 'Verification Progress',
      dataIndex: 'image_verify',
      key: 'verification_progress',
      render: (imageVerify) => {
        const { percent, verified, total } = calculateVerificationProgress(imageVerify);
        return (
          <div>
            <Progress
              percent={Math.round(percent)}
              status={verified === total ? 'success' : 'active'}
              size="small"
            />
            <div style={{ fontSize: '12px', marginTop: '4px' }}>
              {verified} / {total} Verified
            </div>
          </div>
        );
      },
    },
  ];
  

  // Handle row click to navigate to job details
  const handleRowClick = (record) => {
    navigate(`/project/${record.project_id}/job/${record._id}`);
  };

  if (loading) {
    return (
      <Layout>
        <Content style={{ padding: '24px' }}>
          <Spin size="large" />
        </Content>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Content style={{ padding: '24px' }}>
          <Alert message={error} type="error" showIcon />
        </Content>
      </Layout>
    );
  }

  return (
    <Layout>
      <Content style={{ padding: '24px' }}>
        <Title level={2}>Agent Dashboard</Title>

        <Card>
          <Tabs defaultActiveKey="unverified" type="card">
            <TabPane tab="Assigned Jobs" key="assigned">
              <Table
                columns={columns}
                dataSource={assignedJobs}
                rowKey={(record) => record._id}
                onRow={(record) => ({
                  onClick: () => handleRowClick(record),
                  style: { cursor: 'pointer' },
                })}
                pagination={{ pageSize: 5 }}
                locale={{ emptyText: 'No assigned jobs found.' }}
              />
            </TabPane>
            <TabPane tab="Verified/Completed Jobs" key="verified">
              <Table
                columns={columns}
                dataSource={verifiedJobs}
                rowKey={(record) => record._id}
                onRow={(record) => ({
                  onClick: () => handleRowClick(record),
                  style: { cursor: 'pointer' },
                })}
                pagination={{ pageSize: 5 }}
                locale={{ emptyText: 'No verified/completed jobs found.' }}
              />
            </TabPane>
          </Tabs>
        </Card>
      </Content>
    </Layout>
  );
};

export default AgentDashboard;
