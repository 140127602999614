// src/hooks/useFailedJobsData.js

import { useState, useEffect } from 'react';
import { message } from 'antd';
import { fetchFailedItems } from '../utils/failedJobs';

const useFailedJobsData = () => {
  const [failedItems, setFailedItems] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [projectNames, setProjectNames] = useState([]);
  const useDummyData = true; // Set to false to fetch from the API

  useEffect(() => {
    const loadFailedItems = async () => {
      setLoading(true);
      try {
        const data = await fetchFailedItems(useDummyData);
        setFailedItems(data); // Save the fetched failed items
        const projectNames = data.map((item) => item.project_name);
        setProjectNames([...new Set(projectNames)]); // Ensure unique project names
      } catch (error) {
        message.error('Failed to load failed items');
      } finally {
        setLoading(false);
      }
    };

    loadFailedItems();
  }, [useDummyData]);

  const getJobsByProjectName = (projectName) => {
    // Filter jobs from the fetched failedItems based on the selected project name
    const project = failedItems.find(item => item.project_name === projectName);
    const filteredJobs = project ? project.jobs : [];
    return filteredJobs; // Return the filtered jobs directly
  };

  return {
    projectNames,
    loading,
    getJobsByProjectName, // Ensure this function is returned
    jobs // Return jobs as well
  };
};

export default useFailedJobsData;
