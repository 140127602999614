// src/utils/inviteApi.js

import axios from 'axios';
import { getAccessToken } from './auth';
import { API_URL } from '../utils/jobApi';

/**
 * Invite a new agent by username.
 * @param {string} username - The username of the agent to invite.
 * @returns {Object} - The API response containing registration_token, success, and msg.
 */
export const inviteAgent = async (username, role) => {

    console.log("role got:", role)
  try {
    const token = getAccessToken();

    if (!token) {
      throw new Error('User is not authenticated. Please log in.');
    }

    const response = await axios.post(
        `${API_URL}/agents/invite`,
        { 
          username,  // Add the username
          role       // Add the role
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,  // Bearer token for authentication
          },
        }
      );


    return response.data; // Expected: { registration_token: 'token', success: true, msg: 'Token Generated!' }
  } catch (error) {
    console.error('Error inviting agent:', error);
    if (error.response && error.response.data) {
      return error.response.data; // { registration_token: null, success: false, msg: 'Error message' }
    }
    return { registration_token: null, success: false, msg: error.message };
  }
};
