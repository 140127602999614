// src/components/SelectAgent.js

import React from 'react';
import { Card, List, Avatar, Input, Typography } from 'antd';
import { UserOutlined, SearchOutlined } from '@ant-design/icons';

const { Text } = Typography;

const SelectAgent = ({ agentsData, selectedAgentId, handleAgentSelect, searchAgentText, setSearchAgentText }) => {
  const filteredAgents = agentsData.filter((agent) =>
    agent.name.toLowerCase().includes(searchAgentText.toLowerCase())
  );

  return (
    <Card title="Select Agent" style={{ height: '100%' }}>
      <Input
        placeholder="Search agents"
        onChange={(e) => setSearchAgentText(e.target.value)}
        style={{ marginBottom: '8px' }}
        allowClear
        prefix={<SearchOutlined />}
      />
      <List
        dataSource={filteredAgents}
        renderItem={(agent) => (
          <List.Item
            onClick={() => handleAgentSelect(agent.id)}
            style={{
              background: selectedAgentId === agent.id ? '#e6f7ff' : 'transparent',
              cursor: 'pointer',
              padding: '8px 16px',
              borderRadius: '4px',
              marginBottom: '4px',
            }}
          >
            <List.Item.Meta
              avatar={<Avatar icon={<UserOutlined />} />}
              title={<Text strong>{agent.name}</Text>}
            />
          </List.Item>
        )}
        style={{ maxHeight: '400px', overflowY: 'auto', padding: '10px' }}
      />
    </Card>
  );
};

export default SelectAgent;
