// src/hooks/useImageNavigation.js

import { useState } from 'react';

const useImageNavigation = (
  jobDetails,
  form,
  tableData,
  setFormFields,
  setJobDetails
) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const saveCurrentOutput = () => {
    if (!jobDetails || !jobDetails.columns) {
      return;
    }

    const currentOutputObj = jobDetails.job_output[currentImageIndex];
    const outputDataKeys = Object.keys(currentOutputObj);
    const outputDataKey = outputDataKeys.length > 0 ? outputDataKeys[0] : null;
    if (!outputDataKey) {
      return;
    }

    const updatedOutput = form.getFieldsValue();

    const {
      schoolName,
      scholarshipScheme,
      subject,
      district,
      principalName,
      packageSchemes,
      ...restOutput
    } = updatedOutput;

    const newOutput = {};

    newOutput.schoolName = schoolName;
    newOutput.scholarshipScheme = scholarshipScheme;
    newOutput.subject = subject;
    newOutput.district = district;
    newOutput.principalName = principalName;

    tableData.forEach((row) => {
      const rowKey = row.key;
      const rowData = updatedOutput[rowKey] || {};
      newOutput[rowKey] = rowData;
    });

    const updatedJobOutput = [...jobDetails.job_output];
    updatedJobOutput[currentImageIndex][outputDataKey] = newOutput;
    setJobDetails({ ...jobDetails, job_output: updatedJobOutput });
  };

  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      saveCurrentOutput();
      const newIndex = currentImageIndex - 1;
      setCurrentImageIndex(newIndex);

      const previousOutputObj = jobDetails.job_output[newIndex];
      const outputDataKeys = Object.keys(previousOutputObj);
      const outputDataKey = outputDataKeys.length > 0 ? outputDataKeys[0] : null;
      if (outputDataKey) {
        const previousOutput = previousOutputObj[outputDataKey] || {};
        setFormFields(previousOutput, jobDetails.columns);
      }
    }
  };

  const handleNextImage = () => {
    if (currentImageIndex < jobDetails.image_paths.length - 1) {
      saveCurrentOutput();
      const newIndex = currentImageIndex + 1;
      setCurrentImageIndex(newIndex);

      const nextOutputObj = jobDetails.job_output[newIndex];
      const outputDataKeys = Object.keys(nextOutputObj);
      const outputDataKey = outputDataKeys.length > 0 ? outputDataKeys[0] : null;
      if (outputDataKey) {
        const nextOutput = nextOutputObj[outputDataKey] || {};
        setFormFields(nextOutput, jobDetails.columns);
      }
    }
  };

  return {
    currentImageIndex,
    handlePrevImage,
    handleNextImage,
    saveCurrentOutput,
  };
};

export default useImageNavigation;
