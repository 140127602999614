// src/utils/api.js

import axios from 'axios';
import { API_URL } from '../utils/jobApi';
import { getAccessToken } from './auth';  // Ensure this function is correctly implemented


// Function to register an agent
export const registerAgent = async ({ username, role, password, token }) => {
  try {
    const response = await axios.post(`${API_URL}/agents/register`, {
      username,
      role,
      password,
      token,  // Token received from the invitation URL
    });

    return response.data; // Expecting { msg: "...", success: true/false }
  } catch (error) {
    console.error('Registration error:', error);
    throw error;
  }
};
export const fetchRegisteredData = async (imagePath, jobName) => {
  const token = getAccessToken();
  const dataVerify = { imagePath, jobName };

  const response = await axios.post(`${API_URL}/get_registered_data`, dataVerify, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data; // Return the fetched data
};