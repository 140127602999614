// src/components/OCRImagePreview.js
import React, { useEffect, useState } from 'react';
import { Card, Spin } from 'antd';
import Magnifier from 'react-magnifier';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { API_URL } from '../utils/jobApi';

const OCRImagePreview = ({ imageUrl, jobName, image_index }) => {
  const [isVerified, setIsVerified] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImageVerification = async () => {
      try {
        const response = await fetch(`${API_URL}/jobs/image_verification/${image_index}?job_name=${encodeURIComponent(jobName)}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setIsVerified(data.is_verified);
      } catch (error) {
        console.error('Error fetching image verification:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchImageVerification();
  }, [image_index, jobName]);

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <Card bordered={false} style={{ position: 'relative' }}>
      <Magnifier
        src={imageUrl}
        mgWidth={325}
        mgHeight={325}
        zoomFactor={0.7}
        alt="OCR Sample"
        style={{ width: '100%', maxHeight: 'calc(100vh)', objectFit: 'contain' }}
      />
      
      {isVerified === true ? (
        <CheckCircleFilled 
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            fontSize: '50px',
            color: '#7CB342',
            zIndex: 1,
          }} 
        />
      ) : isVerified === false ? (
        <ExclamationCircleFilled 
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            fontSize: '50px',
            color: '#D84315',
            zIndex: 1,
          }} 
        />
      ) : null}
    </Card>
  );
};

export default OCRImagePreview;
